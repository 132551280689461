@import 'bulma/sass/utilities/initial-variables.sass';
@import 'bulma/sass/utilities/functions.sass';
@import 'bulma/bulma.sass';
// @import 'bulma-extensions/extensions.sass';

.event-header div.datetime-box {
  span.datetime-box {
    line-height: 1.25;
    text-align: center;
    display: block;
  }
  span.month {
    text-transform: uppercase;
  }
  span.date {}
  span.time {}
}

.registration-header {
  > div.hero-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .registration-period-label {}
  .registration-period-date {}
}
